import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import PricingTable, {
  ListItem,
  PricingButton,
  PricingHead,
  PricingList,
  PricingPrice
} from './pricing.style';
import Button from 'common/src/components/Button';
import Icon from 'react-icons-kit';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import * as Utils from '../../../utils/Utils';

const pricingCarouselOptions = {
  type: 'slider',
  perView: 2,
  gap: 30,
  breakpoints: {
    1199: {
      perView: 2,
      peek: {
        before: 40,
        after: 40
      }
    },
    990: {
      perView: 2,
      peek: {
        before: 40,
        after: 40
      }
    },
    767: {
      perView: 1,
      peek: {
        before: 40,
        after: 40
      }
    },
    575: {
      perView: 1,
      gap: 15,
      peek: {
        before: 20,
        after: 20
      }
    }
  }
};

const PricingSection = ({
                          sectionWrapper,
                          row,
                          secTitleWrapper,
                          secHeading,
                          secText,
                          nameStyle,
                          descriptionStyle,
                          priceStyle,
                          priceLabelStyle,
                          buttonStyle,
                          buttonFillStyle,
                          listContentStyle
                        }) => {
  const Data = useStaticQuery(graphql`
      query {
          saasModernJson {
              MONTHLY_PRICING_TABLE {
                  name
                  freePlan
                  description
                  price
                  crossPrice
                  priceLabel
                  buttonLabel
                  url
                  listItems {
                      content
                  }
              }
          }
      }
  `);

  const [state] = useState({
    data: Data.saasModernJson.MONTHLY_PRICING_TABLE
  });

  const [subButtonDisabled, setSubButtonDisabled] = useState(false);
  const data = state.data;

  const buySubscription = async () => {
    setSubButtonDisabled(true);
    await Utils.openSubscriptionCheckout();
    setTimeout(() => setSubButtonDisabled(false), 5000);
  };

  return (
    <Box {...sectionWrapper} id="pricing_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="PRICING"/>
          {
            <Heading
              {...secHeading}
              content="Simple and transparent 😇"
            />
          }
          <Text {...PricingSection.defaultProps.secText2}
                content="P.S. It's 50% off! Prices doubling by next week!"/>
        </Box>
        <Box {...row}>
          <GlideCarousel
            carouselSelector="pricing-carousel"
            options={pricingCarouselOptions}
            controls={false}
          >
            <>
              {data.map((pricingTable, index) => (
                <GlideSlide key={`pricing-table-${index}`}>
                  <PricingTable
                    freePlan={pricingTable.freePlan}
                    className="pricing_table"
                    style={pricingTable.freePlan ? null : { border: '1px solid #2aa275' }}
                  >
                    <PricingHead>
                      <Heading content={pricingTable.name} {...nameStyle} />
                      <Text
                        content={pricingTable.description}
                        {...descriptionStyle}
                      />
                    </PricingHead>
                    <PricingPrice>
                      <span style={{ display: 'flex' }}>
                          <Text
                            content={pricingTable.crossPrice} {...PricingSection.defaultProps.crossPriceStyle}
                            style={{
                              textDecoration: 'line-through',
                              marginLeft: 'auto',
                              marginTop: '2px'
                            }}/>
                            <span  {...PricingSection.defaultProps.crossPriceStyle}
                                   style={{
                                     marginRight: 'auto',
                                     marginLeft: '8px',
                                     padding: '2px 6px',
                                     fontSize: '14px',
                                     backgroundColor: '#eaffe6',
                                     color: '#576a4f',
                                     borderRadius: '8px',
                                     height: '18px'
                                   }}>50% off
                            </span>
                      </span>
                      <Text content={pricingTable.price} {...priceStyle} />
                      <Text
                        content={pricingTable.priceLabel}
                        {...priceLabelStyle}
                      />
                    </PricingPrice>
                    <PricingButton>
                      {pricingTable.freePlan ? (
                        <AnchorLink href="#leads">
                          <Button {...{
                            type: 'button',
                            fontSize: '14px',
                            fontWeight: '600',
                            borderRadius: '4px',
                            colors: 'secondaryWithBg'
                          }} title="GET LEADS" onClick={null}/>
                        </AnchorLink>
                      ) : (
                        <Button
                          onClick={buySubscription}
                          disabled={subButtonDisabled}
                          isLoading={subButtonDisabled}
                          title={pricingTable.buttonLabel}
                          {...{
                            type: 'button',
                            fontSize: '14px',
                            fontWeight: '600',
                            borderRadius: '4px'
                          }}
                        />
                      )}
                    </PricingButton>
                    <PricingList>
                      {pricingTable.listItems.map((item, index) => (
                        <ListItem key={`pricing-table-list-${index}`}>
                          <Icon
                            icon={checkmark}
                            className="price_list_icon"
                            size={13}
                          />
                          <Text content={item.content} {...listContentStyle} />
                        </ListItem>
                      ))}
                    </PricingList>
                  </PricingTable>
                </GlideSlide>
              ))}
            </>
          </GlideCarousel>
        </Box>
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['100px', '100px', '100px', '140px', '160px'],
    pb: ['60px', '80px', '80px', '100px']
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center'
  },
  secTitleWrapper: {
    mb: ['50px', '75px']
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px'
  },
  secText2: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    mt: '5px'
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67'
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px'
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px'
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0'
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em'
  },
  crossPriceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['14px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em'
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0'
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    bg: '#fff',
    color: '#2aa275',
    colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%'
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '200px',
    maxWidth: '100%'
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0'
  }
};

export default PricingSection;
